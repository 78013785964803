.p-tieredmenu {
  background-color: $menu-background !important;
  border: none !important;
  color: #ffffff !important;
  padding: 0 !important;
  margin: 0 !important;

  .p-menuitem-link {
    color: #ffffff !important;
    padding: 1.2rem 1.5rem !important;

    .p-menuitem-text {
      color: #ffffff !important;
    }
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
  }

  .p-menuitem-link.p-menuitem-link-active {
    color: #ffffff !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .p-menuitem-link:not(.p-menuitem-link-active) {
    background-color: unset !important;
  }
}
