.p-calendar--fullWidth {
  .p-calendar {
    width: 100%;
  }

  .p-datepicker {
    width: 100%;
  }

  .p-inputtext {
    font-family: inherit !important;
    border-right-width: 0 !important;
  }

  .p-button-icon-only {
    background-color: #fff !important;
    border-color: #e1e1e1;
    border-left-width: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    color: #495057 !important;
  }

  .p-button-icon-only:enabled:hover {
    border-color: #e1e1e1 !important;
  }

  .p-datepicker:not(.p-datepicker-touch-ui) {
    min-width: min-content;
  }
}

p-calendar.ng-invalid.ng-touched > .p-calendar {
  > .p-inputtext {
    border-color: #c84837;
  }

  > .p-button,
  > .p-button:enabled:hover {
    border-color: #c84837 !important;
  }
}

p-calendar.ng-invalid.ng-touched > .p-calendar > .p-inputtext:enabled:focus {
  border-color: #c84837;
  box-shadow: 0 0 0 0.2rem rgb(200 72 55 / 25%);
}
