.form-group--noMargin {
  margin: 0;
}

input.form-control.ng-invalid.ng-touched {
  border-color: #c84837;
}

input.form-control.ng-invalid.ng-touched:focus {
  border-color: #c84837;
  box-shadow: 0 0 0 0.2rem rgb(200 72 55 / 25%);
}

input.form-control + .invalid-feedback {
  display: none;
}

input.form-control.ng-invalid.ng-touched + .invalid-feedback {
  background-color: #f4dad7;
  border-radius: 0.2rem;
  color: #401758;
  display: block;
  margin-top: 0.4rem;
  padding: 0.25rem 0.75rem;
}

input::placeholder {
  color: #bfbfbf !important;
}

input[placeholder] {
  text-overflow: ellipsis;
}
