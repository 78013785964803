.p-chips {
  width: 100%;

  .p-inputtext {
    display: inline-flex !important;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    row-gap: 0.25rem;
    width: 100%;
  }

  .p-inputtext:not(.p-disabled).p-focus {
    border-color: #007fe5 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 56 101 / 25%) !important;
  }

  .p-chips-token {
    background-color: $primary;
    border-radius: 0.2rem;
    color: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .p-chips-token-label {
      padding-right: 0.2rem;
      margin-top: 0.05rem;
      margin-bottom: 0.2rem;
    }
  }

  .p-chips-input-token {
    padding-right: 0 !important;
  }
}

p-chips.form-control {
  height: fit-content !important;
}
