.p-tree {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: unset !important;

  .p-tree-container {
    .p-treenode {
      background-color: transparent !important;
      border: unset !important;

      .p-treenode-content {
        .p-tree-toggler {
          color: #fff !important;
        }

        > span {
          color: #fff !important;
        }
      }

      .p-treenode-selectable.p-highlight,
      .p-treenode-selectable.p-highlight + .p-treenode-children {
        background-color: transparent !important;

        > .p-tree-toggler > .p-tree-toggler-icon {
          color: $primary !important;
        }

        .p-checkbox-box.p-highlight,
        .p-checkbox-icon {
          background-color: $primary !important;
          border-color: $primary !important;
        }

        > span:not(.p-checkbox-icon) {
          color: $primary !important;
        }
      }
    }

    .p-treenode.p-treenode-leaf .p-treenode-content {
      transform: translateX(calc((1rem + 14px) * -1));
    }
  }

  .p-tree-empty-message {
    color: #fff;
  }
}
