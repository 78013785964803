.p-datatable {
  .p-datatable-thead > tr > th {
    border: none !important;
    background-color: unset !important;
    color: #ffffff !important;
  }

  .p-datatable-tbody > tr > td {
    border: none !important;
  }

  .p-datatable-tbody > tr {
    background-color: unset !important;
    color: #ffffff !important;
  }

  .p-sortable-column .p-sortable-column-icon {
    color: #ffffff !important;
  }
}

.p-datatable--disableSortHover {
  .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    background-color: unset !important;
  }

  .p-paginator {
    display: flex;
    justify-content: flex-end;

    .p-paginator-page,
    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      background-color: unset !important;
      color: #ffffff !important;
    }

    .p-paginator-element:not(.p-disabled):not(.p-highlight):hover {
      border-color: #ffffff !important;
    }

    .p-highlight:not(.p-disabled) {
      border-color: #ffffff !important;
    }
  }
}
