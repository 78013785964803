.p-dropdown {
  font-family: inherit !important;

  .p-inputtext {
    font-size: 1rem;
  }

  .p-placeholder {
    color: #bfbfbf;
  }
}

p-dropdown.form-control {
  border: 0rem;
  padding: 0rem;
}

// Dropdown on invalid state.
p-dropdown.ng-invalid.ng-touched {
  .p-dropdown {
    border-color: #c84837;
  }

  .p-dropdown.p-focus {
    border-color: #c84837;
    box-shadow: 0 0 0 0.2rem rgb(200 72 55 / 25%);
  }
}

// Dropdown with input group
.input-group {
  .input-group-prepend + p-dropdown .p-dropdown {
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    border-left-width: 0rem !important;
  }
}
