.p-dialog {
  .p-dialog-header {
    background-color: #001128 !important;
  }

  .p-dialog-content {
    color: #fff;
    background-color: #002b49;
  }

  .p-dialog-footer {
    background-color: #002b49;
    border: none !important;

    .btn + .btn {
      margin-left: 1rem;
    }
  }
}

.p-dialog--maxWidth80Percent {
  max-width: 80% !important;
}
