.btn,
.btn:hover {
  color: #fff;
}

.btn-primary {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #002555 !important;

  &:focus {
    background-color: $primary !important;
    border-color: $primary-dark !important;
    color: #002555 !important;
  }

  &:hover {
    background-color: $primary-dark !important;
    border-color: $primary-dark !important;
    color: #002555 !important;
  }
}

.btn-outline-primary {
  border-color: $primary !important;
  color: #ffffff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
