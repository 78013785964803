.p-paginator--noBackground {
  .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    background-color: unset !important;
  }
}

.p-paginator--whiteColor {
  .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    color: #ffffff !important;
  }

  .p-paginator-element:not(.p-disabled):not(.p-highlight):hover {
    border-color: #ffffff !important;
  }

  .p-highlight:not(.p-disabled) {
    border-color: #ffffff !important;
  }
}
