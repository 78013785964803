.p-autocomplete.p-autocomplete-multiple {
  .p-inputtext {
    display: inline-flex !important;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    row-gap: 0.25rem;
    width: 100%;
  }

  .p-inputtext:not(.p-disabled).p-focus {
    border-color: #007fe5 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 56 101 / 25%) !important;
  }

  .p-autocomplete-multiple-container {
    .p-autocomplete-token {
      background-color: $primary;
      border-radius: 0.2rem;
      color: #fff;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }

    .p-autocomplete-token-label {
      margin-top: 0.05rem;
      margin-bottom: 0.2rem;
      margin-right: 0rem;
    }
  }

  .p-autocomplete-input-token {
    padding-right: 0 !important;
  }
}
