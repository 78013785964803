@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato/Lato-Bold.ttf');
  font-weight: bold;
}

// Import Jabil ui/ux styles.
@import '~@jabil/ui-styles/dist/main.css';
@import '~@jabil/ui-styles/dist/ui-ng.css';

@import 'assets/styles/input';
@import 'assets/styles/textarea';
@import 'assets/styles/primeng/main.scss';

html,
body {
  height: 100%;
}
body {
  color: #fff;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: #fff;
}

.block {
  background-color: rgba($color: #ffffff, $alpha: 0.2);
  margin-bottom: unset;
}

.block--dark {
  background: rgba($color: #002b49, $alpha: 0.98);
}

label.required::before {
  content: '*';
  color: red;
  margin-left: -0.75rem;
  position: absolute;
}
