@import '/src/assets/styles/primeng/variables';

.p-breadcrumb {
  padding-top: unset !important;

  ul li .p-menuitem-link {
    color: $primary !important;
    cursor: pointer;
  }

  ul li:last-child .p-menuitem-link {
    color: #ffffff !important;
  }

  .p-menuitem-text {
    font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, roboto,
      Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1.2rem;
  }
}

.p-breadcrumb--noPadding {
  padding: 0rem !important;
}
